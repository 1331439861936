let options: any;
export default options = [{"value":"93","name":"AFEGANISTAO"},
{"value":"27","name":"AFRICA DOUL"},
{"value":"1","name":"ALASCA"},
{"value":"355","name":"ALBANIA"},
{"value":"49","name":"ALEMANHA"},
{"value":"376","name":"ANDORRA"},
{"value":"244","name":"ANGOLA"},
{"value":"1","name":"ANGUILLA"},
{"value":"599","name":"ANT.HOLANDESAS"},
{"value":"1","name":"ANTIGUA"},
{"value":"966","name":"ARABIAAUDITA"},
{"value":"213","name":"ARGELIA"},
{"value":"54","name":"ARGENTINA"},
{"value":"374","name":"ARMENIA"},
{"value":"297","name":"ARUBA"},
{"value":"247","name":"ASCENSAO ILHAS"},
{"value":"61","name":"AUSTRALIA"},
{"value":"43","name":"AUSTRIA"},
{"value":"994","name":"AZERBAIJAO"},
{"value":"1","name":"BAHAMAS"},
{"value":"880","name":"BANGLADESH"},
{"value":"1","name":"BARBADOS"},
{"value":"973","name":"BAREIN"},
{"value":"375","name":"BELARUS"},
{"value":"32","name":"BELGICA"},
{"value":"501","name":"BELIZE"},
{"value":"229","name":"BENIN"},
{"value":"1","name":"BERMUDAS"},
{"value":"591","name":"BOLIVIA"},
{"value":"387","name":"BOSNIA E HERZEGOVINA"},
{"value":"267","name":"BOTSUANA"},
{"value":"55","name":"BRASIL"},
{"value":"673","name":"BRUNEI"},
{"value":"359","name":"BULGARIA"},
{"value":"226","name":"BURKINA FASO"},
{"value":"257","name":"BURUNDI"},
{"value":"975","name":"BUTAO"},
{"value":"238","name":"CABO VERDE"},
{"value":"237","name":"CAMAROES"},
{"value":"855","name":"CAMPUCHEA"},
{"value":"1","name":"CANADA"},
{"value":"7","name":"CASAQUISTAO"},
{"value":"974","name":"CATAR"},
{"value":"1","name":"CAYMAN ILHAS"},
{"value":"235","name":"CHADE"},
{"value":"56","name":"CHILE"},
{"value":"86","name":"CHINA"},
{"value":"357","name":"CHIPRE"},
{"value":"65","name":"CINGAPURA"},
{"value":"57","name":"COLOMBIA"},
{"value":"269","name":"COMORES"},
{"value":"242","name":"CONGO"},
{"value":"682","name":"COOK ILHAS"},
{"value":"850","name":"COREIA DOORTE"},
{"value":"82","name":"COREIA DOUL"},
{"value":"225","name":"COSTA DO MARFIM"},
{"value":"506","name":"COSTA RICA"},
{"value":"385","name":"CROACIA"},
{"value":"53","name":"CUBA"},
{"value":"246","name":"DIEGO GARCIA"},
{"value":"45","name":"DINAMARCA"},
{"value":"253","name":"DJIBUTI"},
{"value":"1","name":"DOMINICA"},
{"value":"1","name":"DOMINICANA REP"},
{"value":"20","name":"EGITO"},
{"value":"503","name":"ELALVADOR"},
{"value":"971","name":"EMIRADOS A. UNIDOS"},
{"value":"593","name":"EQUADOR"},
{"value":"291","name":"ERITREA"},
{"value":"386","name":"ESLOVENIA"},
{"value":"34","name":"ESPANHA"},
{"value":"1","name":"ESTADOS UNIDOS"},
{"value":"372","name":"ESTONIA"},
{"value":"251","name":"ETIOPIA"},
{"value":"298","name":"FAROE ILHAS"},
{"value":"679","name":"FIJI"},
{"value":"63","name":"FILIPINAS"},
{"value":"358","name":"FINLANDIA"},
{"value":"886","name":"FORMOSA"},
{"value":"33","name":"FRANCA"},
{"value":"241","name":"GABAO"},
{"value":"220","name":"GAMBIA"},
{"value":"233","name":"GANA"},
{"value":"995","name":"GEORGIA"},
{"value":"350","name":"GIBRALTAR"},
{"value":"1","name":"GRANADA"},
{"value":"30","name":"GRECIA"},
{"value":"299","name":"GROENLANDIA"},
{"value":"590","name":"GUADALUPE"},
{"value":"1","name":"GUAM"},
{"value":"502","name":"GUATEMALA"},
{"value":"592","name":"GUIANA"},
{"value":"594","name":"GUIANA FRANCESA"},
{"value":"224","name":"GUINE"},
{"value":"240","name":"GUINE EQUATORIAL"},
{"value":"245","name":"GUINE-BISSAU"},
{"value":"509","name":"HAITI"},
{"value":"1","name":"HAVAI"},
{"value":"31","name":"HOLANDA"},
{"value":"504","name":"HONDURAS"},
{"value":"852","name":"HONG KONG"},
{"value":"36","name":"HUNGRIA"},
{"value":"967","name":"IEMEN REP."},
{"value":"91","name":"INDIA"},
{"value":"62","name":"INDONESIA"},
{"value":"98","name":"IRA"},
{"value":"964","name":"IRAQUE"},
{"value":"353","name":"IRLANDA"},
{"value":"354","name":"ISLANDIA"},
{"value":"972","name":"ISRAEL"},
{"value":"39","name":"ITALIA"},
{"value":"1","name":"JAMAICA"},
{"value":"81","name":"JAPAO"},
{"value":"962","name":"JORDANIA"},
{"value":"686","name":"KIRIBATI"},
{"value":"965","name":"KUWEIT"},
{"value":"856","name":"LAOS"},
{"value":"266","name":"LESOTO"},
{"value":"371","name":"LETONIA"},
{"value":"961","name":"LIBANO"},
{"value":"231","name":"LIBERIA"},
{"value":"218","name":"LIBIA"},
{"value":"423","name":"LIECHTENSTEIN"},
{"value":"370","name":"LITUANIA"},
{"value":"352","name":"LUXEMBURGO"},
{"value":"853","name":"MACAU"},
{"value":"389","name":"MACEDONIA"},
{"value":"261","name":"MADAGASCAR"},
{"value":"60","name":"MALASIA"},
{"value":"265","name":"MALAVI"},
{"value":"960","name":"MALDIVAS"},
{"value":"223","name":"MALI"},
{"value":"356","name":"MALTA"},
{"value":"500","name":"MALVINAS ILHAS"},
{"value":"1","name":"MARIANASORTE ISL."},
{"value":"212","name":"MARROCOS"},
{"value":"692","name":"MARSHALL ILHAS"},
{"value":"596","name":"MARTINICA"},
{"value":"230","name":"MAURICIO"},
{"value":"222","name":"MAURITANIA"},
{"value":"269","name":"MAYOTTE(ILHAS)"},
{"value":"52","name":"MEXICO"},
{"value":"691","name":"MICRONESIA"},
{"value":"838","name":"MIDWAY ILHAS"},
{"value":"258","name":"MOCAMBIQUE"},
{"value":"373","name":"MOLDOVA"},
{"value":"976","name":"MONGOLIA"},
{"value":"382","name":"MONTENEGRO"},
{"value":"1","name":"MONTSERRAT"},
{"value":"264","name":"NAMIBIA"},
{"value":"674","name":"NAURU"},
{"value":"977","name":"NEPAL"},
{"value":"505","name":"NICARAGUA"},
{"value":"227","name":"NIGER"},
{"value":"234","name":"NIGERIA"},
{"value":"683","name":"NIUE"},
{"value":"672","name":"NORFOLK ILHA"},
{"value":"47","name":"NORUEGA"},
{"value":"687","name":"NOVA CALEDONIA"},
{"value":"64","name":"NOVA ZELANDIA"},
{"value":"968","name":"OMA"},
{"value":"680","name":"PALAU"},
{"value":"970","name":"PALESTINA"},
{"value":"507","name":"PANAMA"},
{"value":"675","name":"PAPUAOVA GUINE"},
{"value":"92","name":"PAQUISTAO"},
{"value":"595","name":"PARAGUAI"},
{"value":"51","name":"PERU"},
{"value":"48","name":"POLONIA"},
{"value":"1","name":"PORTO RICO"},
{"value":"351","name":"PORTUGAL"},
{"value":"377","name":"PRINCIPADO MONACO"},
{"value":"254","name":"QUENIA"},
{"value":"996","name":"QUIRGUIZIA"},
{"value":"44","name":"REINO UNIDO"},
{"value":"236","name":"REP.CENTRO AFRICANA"},
{"value":"421","name":"REP.ESLOVAQUIA"},
{"value":"420","name":"REP.TCHECA"},
{"value":"262","name":"REUNIAO ILHAS"},
{"value":"854","name":"RODRIGUES ILHAS"},
{"value":"40","name":"ROMENIA"},
{"value":"250","name":"RUANDA"},
{"value":"7","name":"RUSSIA"},
{"value":"1","name":"S.KITTS &NEVIS"},
{"value":"1","name":"S.VICENTE ILHAS"},
{"value":"833","name":"SAARA ESPANHOL"},
{"value":"677","name":"SALOMAO ILHAS"},
{"value":"685","name":"SAMOA"},
{"value":"684","name":"SAMOA AMERICANA"},
{"value":"1","name":"SANTA LUCIA"},
{"value":"378","name":"SAO MARINO"},
{"value":"239","name":"SAO TOME E PRINCIPE"},
{"value":"221","name":"SENEGAL"},
{"value":"232","name":"SERRA LEOA"},
{"value":"381","name":"SÃ�RVIA"},
{"value":"248","name":"SEYCHELLES"},
{"value":"963","name":"SIRIA"},
{"value":"252","name":"SOMALIA"},
{"value":"94","name":"SRI-LANKA"},
{"value":"508","name":"ST.PIERRE E MIQUELON"},
{"value":"290","name":"STA HELENA ILHAS"},
{"value":"268","name":"SUAZILANDIA"},
{"value":"249","name":"SUDAO"},
{"value":"46","name":"SUECIA"},
{"value":"41","name":"SUICA"},
{"value":"597","name":"SURINAME"},
{"value":"992","name":"TADJIQUISTAO"},
{"value":"66","name":"TAILANDIA"},
{"value":"689","name":"TAITI"},
{"value":"255","name":"TANZANIA"},
{"value":"672","name":"TERRIT.EXT.AUSTRALIA"},
{"value":"670","name":"TIMOR LESTE"},
{"value":"228","name":"TOGO"},
{"value":"676","name":"TONGA"},
{"value":"690","name":"TOQUELAU"},
{"value":"1","name":"TRINIDAD E TOBAGO"},
{"value":"216","name":"TUNISIA"},
{"value":"993","name":"TURCOMENIA"},
{"value":"1","name":"TURKS E CAICOS ILHAS"},
{"value":"90","name":"TURQUIA"},
{"value":"688","name":"TUVALU"},
{"value":"380","name":"UCRANIA"},
{"value":"256","name":"UGANDA"},
{"value":"95","name":"UNIAO DE MYANMAR"},
{"value":"598","name":"URUGUAI"},
{"value":"998","name":"UZBEQUISTAO"},
{"value":"678","name":"VANUATU"},
{"value":"58","name":"VENEZUELA"},
{"value":"84","name":"VIETNA"},
{"value":"1","name":"VIRGENS A. ILHAS"},
{"value":"1","name":"VIRGENS B.ILHAS"},
{"value":"839","name":"WAKE ILHAS"},
{"value":"681","name":"WALLIS E FUTUNA"},
{"value":"243","name":"ZAIRE"},
{"value":"260","name":"ZAMBIA"},
{"value":"259","name":"ZANZIBAR"},
{"value":"263","name":"ZIMBABUE"}
]