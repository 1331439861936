export const conversaDummy = [
    // {texto: "Texto mockado para testes", horario: "14:56"},
    // {texto: "Texto mockado ", horario: "14:56"},
    // {nome: "Queiroz", texto: "Si", horario: "14:56"},
    // {nome: "Queiroz", texto: "Amidor tem gluter 😥", horario: "14:56"},
    // {texto: "😫", horario: "14:56"},
    // {texto: "Noooooo", horario: "14:56"},
    // {nome: "Queiroz", texto: "Si", horario: "14:56"},
    // {nome: "Queiroz", texto: "Passar bem", horario: "14:56"},
    // {nome: "Queiroz", texto: "Dolore iste eaque molestias. Eius iure ut eaque accusantium. Voluptas repellendus nobis. Saepe nam accusantium magni veniam qui enim mollitia excepturi sapiente.Dolore iste eaque molestias. Eius iure ut eaque accusantium. Voluptas repellendus nobis. Saepe nam accusantium magni veniam qui enim mollitia excepturi sapiente.Dolore iste eaque molestias. Eius iure ut eaque accusantium. Voluptas repellendus nobis. Saepe nam accusantium magni veniam qui enim mollitia excepturi sapiente. ", horario: "14:56"},
    // {texto: "Dolore iste eaque molestias. Eius iure ut eaque accusantium. Voluptas repellendus nobis. Saepe nam accusantium magni veniam qui enim mollitia excepturi sapiente.Dolore iste eaque molestias. Eius iure ut eaque accusantium. Voluptas repellendus nobis. Saepe nam accusantium magni veniam qui enim mollitia excepturi sapiente.Dolore iste eaque molestias. Eius iure ut eaque accusantium. Voluptas repellendus nobis. Saepe nam accusantium magni veniam qui enim mollitia excepturi sapiente. ", horario: "14:56"},
]